.side_bar_mask {
  transition: all 0.3s ease;
  font-family: $font-roboto;
  &.active {
    @include media-max(1200px) {
      @include fixed-full;
      z-index: 10;
      background: rgba(0, 0, 0, 0.2);
    }
  }
  .side_bar_wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 270px;
    background: #301D57;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
    z-index: 10;
    overflow-y: scroll;
    transition: all 0.25s ease;
    &::-webkit-scrollbar {
      width: 0;
    }
    @include media-max(1400px) {
      width: 240px;
    }
    @include media-max(1200px) {
      width: 270px;
      transform: translateX(-300px);
      transition: all 0.25s ease;
      &.active {
        transform: translateX(0);
      }
    }
    @include media-min(1200px) {
      &.active:not(:hover) {
        width: 80px;
        .top {
          padding: 10px;
          grid-template-columns: 30px;
          justify-content: center;
          .logo {
            img {
              display: none;
            }
            .favicon {
              display: block;
            }
          }
          .circle {
            display: none;
          }
        }
        .content {
          .sidebar_dropdown {
            .dropdown_label {
              grid-template-columns: 100%;
              div {
                grid-template-columns: 100%;
                justify-items: center;
                span {
                  display: none;
                }
              }
              .arrow {
                display: none;
              }
            }
          }
          li {
            a {
              grid-template-columns: 100%;
              justify-items: center;
              span {
                display: none;
              }
            }
          }
        }
      }
    }
    .top {
      display: grid;
      grid-template-columns: 1fr 16px;
      align-items: center;
      grid-gap: 20px;
      padding: 20px;
      .logo {
        display: block;
        height: 32px;
        img {
          width: 100%;
          cursor: pointer;
        }
        .favicon {
          display: none;
          cursor: pointer;
        }
      }
      svg {
        color: $color-purple;
        cursor: pointer;
      }
      .circle {
        @include media-max(1200px) {
          display: none;
        }
      }
      .times {
        display: none;
        @include media-max(1200px) {
          display: block;
        }
      }
    }
    .content {
      padding-top: 70px;
      padding-bottom: 20px;
      .sidebar_dropdown {
        @include transition;
        .dropdown_label {
          display: grid;
          grid-template-columns: 1fr 20px;
          align-items: center;
          grid-gap: 10px;
          color: white;
          margin: 0 15px;
          padding: 11px 10px;
          font-size: 15px;
          font-weight: 400;
          border-radius: 4px;
          cursor: pointer;
          @include transition;
          &.active {
            background: rgba(28, 139, 46, 0.726);
            .arrow {
              transform: rotate(0deg);
            }
            & + .dropdown_items_wrap {
              display: block;
            }
          }
          &:hover {
            div {
              margin-left: 5px;
            }
          }
          div {
            display: grid;
            grid-template-columns: 20px 1fr;
            grid-gap: 10px;
            align-items: center;
            @include transition;
            svg {
              width: 20px;
              height: 20px;
              font-size: 20px;
            }
          }
          .arrow {
            width: 20px;
            height: 20px;
            font-size: 20px;
            transform: rotate(-90deg);
            @include transition;
          }
        }
        .dropdown_items_wrap {
          display: none;
          .dropdown_items {
            padding: 10px 0;
          }
        }
      }
      li {
        margin: 0 15px;
        border-radius: 4px;
        &.active {
          background: $color-purple;
          box-shadow: 0 0 10px 1px rgba(247, 110, 190, 0.7);
          color: $color-white;
          a {
            color: $color-white;
          }
        }
        a {
          @include full-size;
          display: grid;
          grid-template-columns: 20px 1fr;
          align-items: center;
          grid-gap: 10px;
          color: white;
          padding: 11px 10px;
          font-size: 15px;
          font-weight: 400;
          cursor: pointer;
          @include transition;
          &:hover {
            padding-left: 15px;
          }
          svg {
            width: 20px;
            height: 20px;
            font-size: 20px;
          }
        }
      }
    }
  }
}
